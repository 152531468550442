import { Controller } from 'stimulus'

export default class extends Controller {

  showAll() {
    var searchElements = this.element.getElementsByClassName('search__tag hidden')

    for (var i = 0; i < searchElements.length; i++) {
      searchElements[i].classList.remove('hidden')
    }
  }

}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['label', 'container', 'name', 'destroy', 'buttonsWrapper']
  static values = { initialName: String, initialLink: String }

  initializeValues() {
    this.buttonsWrapperTarget.classList.remove('not-saved-warning')
    this.initialName = this.nameTarget.value
  }

  updateName() {
    this.labelTarget.textContent = this.nameTarget.value || '-'
    this.containerTarget.classList.add('show')
    if (this.checkValidity()) {
      this.element.classList.remove('blank-warning')
    } else {
      this.element.classList.add('blank-warning')
    }
  }

  deleteElement() {
    this.destroyTarget.value = 1
    this.element.style.display = 'none'
    this.nameTarget.remove()
  }

}

import DynamicAttributeController from './dynamic_attribute_controller'

export default class extends DynamicAttributeController {

  static targets = ['file']

  initializeValues() {
    super.initializeValues()
    this.initialFile = this.fileTarget.value
  }

  cancelFileChanges() {
    if(this.initialName || this.initialFile) {
      this.nameTarget.value = this.initialName || ''
      this.fileTarget.value = ''
      this.fileTarget.dispatchEvent(new Event('change'))
      this.updateName()
    } else {
      this.deleteElement()
    }
  }

  deleteElement() {
    super.deleteElement()
    this.fileTarget.remove()
  }

  checkValidity() {
    return this.nameTarget.checkValidity() && this.fileTarget.checkValidity()
  }

}

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button']
  static values = { text: String, button: String }

  update() {
    var modal = this.buttonTarget.getAttribute('data-bs-target')

    $(modal).find('#dynamicText').html(this.textValue)
    $(modal).find('#dynamicButton').html(this.buttonValue)
  }
}

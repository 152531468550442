import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['label', 'input']
  static values = { url: String }

  connect() {
    if (this.urlValue) {
      this.updateName()
    }
  }

  updateName() {
    var newName = (this.inputTarget.files[0] || {}).name || this.urlValue
    this.labelTarget.textContent = (newName || $('meta[name=new_file]').attr('content'))
  }

  openFileUploader(event) {
    this.inputTarget.click()
    event.preventDefault()
  }
}

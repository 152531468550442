import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'template']
  static values = { lastId: Number }

  addAttribute() {
    this.lastIdValue = this.lastIdValue + 1
    this.contentTarget.insertAdjacentHTML('beforeend', this.templateTarget.innerHTML.replace(/ID/g, this.lastIdValue));
  }
}

import { Controller } from 'stimulus'
import Cropper from 'cropperjs'

var cropper

export default class extends Controller {
  static targets = ['image', 'xOffset', 'yOffset', 'height', 'width']

  connect() {
    this.initializeCropper()
  }

  initializeImageAndCropper(event) {
    if (!event.target.files[0]) {
      return
    }

    var url = URL.createObjectURL(event.target.files[0])
    if (url) {
      if (cropper) {
        cropper.destroy()
      }
      this.initializeImage(url)
      this.initializeCropper()
    }
  }

  initializeImage(url) {
    // cropperjs need this src reset to properly set canvas width and height to the new image's
    this.imageTarget.src = ''
    this.imageTarget.src = url
  }

  initializeCropper() {
    var image = this.imageTarget
    var xOffset = this.xOffsetTarget
    var yOffset = this.yOffsetTarget
    var width = this.widthTarget
    var height = this.heightTarget

    if (cropper) {
      cropper.destroy()
    }

    cropper = new Cropper(image, {
      viewMode: 3,
      aspectRatio: 2.74,
      dragMode: 'move',
      responsive: false,
      zoomable: false,
      minCropBoxWidth: 500,
      autoCropArea: 1,
      data: {
        x: parseFloat(xOffset.value),
        y: parseFloat(yOffset.value),
        width: parseFloat(width.value),
        height: parseFloat(height.value)
      },
      crop(event) {
        xOffset.value = event.detail.x
        yOffset.value = event.detail.y
        width.value = event.detail.width
        height.value = event.detail.height
      }
    });
  }

  disconnect() {
    cropper.destroy()
  }
}
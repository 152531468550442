import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ['content']

  connect() {
    if (Cookies.get('cookies_accepted') === undefined) {
      this.contentTarget.style.display = 'block'
    }
  }

  acceptCookies() {
    Cookies.set('cookies_accepted', true)
    this.contentTarget.style.display = 'none'
  }
}

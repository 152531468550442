import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['preview', 'input']
  static values = { url: String }

  connect() {
    this.updatePreviewOnBackground()
  }

  updatePreview() {
    var image = this.inputTarget.files[0]
    var url = image ? URL.createObjectURL(image) : this.urlValue

    if (url) this.previewTarget.innerHTML = '<img src= ' + url + '></img>'
  }

  updatePreviewOnBackground() {
    var image = this.inputTarget.files[0]
    var url = image ? URL.createObjectURL(image) : this.urlValue

    if (url) {
      this.previewTarget.style.backgroundImage = 'url(' + url + ')'
      this.previewTarget.style.backgroundSize = '100%'
      this.previewTarget.style.fontSize = '0'  
    } else {
      this.previewTarget.style.backgroundImage = null
      this.previewTarget.style.backgroundSize = null
      this.previewTarget.style.fontSize = null
    }
  }
}

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import $ from 'jquery'
import 'select2'
import 'bootstrap'
import datepicker from 'js-datepicker'
import 'daterangepicker'
import moment from 'moment'
import "youtube"

import Highcharts from 'highcharts'
//import highchartsMore from 'highcharts-more';
//highchartsMore(highcharts);
//import Highcharts from 'highcharts'
//import HighchartsMore from 'highcharts/highcharts-more';
//import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
//import HighchartsExporting from 'highcharts/modules/exporting';


const application = Application.start()
const context = require.context('../controllers', true, /\.js$/)
const feather = require('feather-icons')
application.load(definitionsFromContext(context))

window.Rails = Rails
Rails.start()
Turbolinks.start()
ActiveStorage.start()

window.Highcharts = Highcharts;

require('trix')
require('@rails/actiontext')
require("jquery-ui")

document.addEventListener('turbolinks:load', function() {
  // Functions
  function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  feather.replace()
  $('.select2-container').remove()

  // Select2 init
  $('.select2').each(function() {
    var placeholder = $(this).attr('placeholder');
    $(this).select2({
      placeholder: placeholder || $('meta[name=select2_placeholder]').attr('content')
    })
  }),
  $('.select2-tags').each(function() {
    var placeholder = $(this).attr('placeholder');
    $(this).select2({
      tags: true,
      placeholder: placeholder || $('meta[name=select2_tags_placeholder]').attr('content')
    })
  }),
  $('.select2-tags-capitalized').each(function() {
    var placeholder = $(this).attr('placeholder');
    var that = this;
    $(that).select2({
      tags: true,
      placeholder: placeholder || $('meta[name=select2_tags_placeholder]').attr('content'),
      createTag: function(params) {
        var term = capitalize(params.term);

        return {
          id: term,
          text: term,
        }
      },
    })
  }),
  $('.select2-optional').each(function() {
    var placeholder = $(this).attr('placeholder');
    $(this).select2({
      allowClear: true,
      placeholder: placeholder || $('meta[name=none_placeholder]').attr('content')
    })
  })

  // Datepicker init
  if (document.querySelector('.datepicker')) {
    datepicker('.datepicker', {id:1})
  }

  // Daterange init
  if (document.querySelector('.date_range')) {
    var $dr = $('.date_range')
    var start = undefined
    var end = undefined
    var timeframe = $dr.data("timeframe")
    var update = $dr.data("update")
    var ranges = $dr.data("ranges")

    if (timeframe != undefined && timeframe !== "" && timeframe != "All") {
      var dates = $dr.data("timeframe").split(" - ")
      start = moment(dates[0], "MM/DD/YYYY")
      end = moment(dates[1], "MM/DD/YYYY")
      // If the dates are invalid we set them to (today - tomorrow)
      if (!start.isValid()){
        // Today
        start = moment();
      }
      if (!end.isValid()){
        // Tomorrow
        end = moment().add(1,'days');
      }
    }

    $dr.daterangepicker({
      startDate: start,
      endDate: end,
      ranges: ranges ? {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'All Time': ["All"],
      } : undefined,
      alwaysShowCalendars: true,
      showCustomRangeLabel: false,
      autoApply: true,
    }, function(start, end, label ) {
      if (update) {
        var url = new URL(location.href)
        if (start._i === "All") {
          url.searchParams.set("filters[timeframe]", "All")
        } else {
          url.searchParams.set("filters[timeframe]", start.format("MM/DD/YYYY") + " - " + end.format("MM/DD/YYYY"))
        }

        location.href = url.href
      }
    })

    if (timeframe === "") {
      $dr.val("Select timeframe")
    } else if (timeframe === "All"){
      $dr.val("All")
    }
  }

})

// Trix file validation config
window.addEventListener('trix-file-accept', function(event) {
  if (!event.file.type.includes('image')) {
    event.preventDefault()
    alert($('meta[name=file_type_error]').attr('content'))
  }
})

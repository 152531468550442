import DynamicAttributeController from './dynamic_attribute_controller'

export default class extends DynamicAttributeController {

  static targets = ['link']

  initializeValues() {
    super.initializeValues()
    this.initialLink = this.linkTarget.value
  }

  cancelChanges() {
    if(this.initialName || this.initialLink) {
      this.nameTarget.value = this.initialName || ''
      this.linkTarget.value = this.initialLink || ''
      this.updateName()
    } else {
      this.deleteElement()
    }
  }

  deleteElement() {
    super.deleteElement()
    this.linkTarget.remove()
  }

  checkValidity() {
    return this.nameTarget.checkValidity() && this.linkTarget.checkValidity()
  }

}
